const clients = [
    {
        id: 2,
        system: "POC",
        module: 'ZNAP_OPEX_PLANNING',
        modulePath: 'opex',
        moduleName: 'opex',
        language: 'pt',
        port: 8086,
        theme: "poc.js",
        env: 'prod'
    }
]

const getClientConfigs = (clientId) => clients.find(client => client.id === clientId)
const configs = getClientConfigs(2)

export default {
  ...configs,
};
